import { getAdminListings, postAdminListingNew, getAdminUsers, updateListing } from '../../util/api';
import { listingHeaders, userHeaders } from './Configs/Headers';
import findIndex from 'lodash/findIndex';
// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/AdminPanelPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/AdminPanelPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/AdminPanelPage/FETCH_LISTINGS_ERROR';

export const FETCH_USERS_REQUEST = 'app/AdminPanelPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/AdminPanelPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/AdminPanelPage/FETCH_USERS_ERROR';

export const UPDATE_SEARCH_VALUE = 'app/AdminPanelPage/UPDATE_SEARCH_VALUE';
export const UPDATE_CATEGORY = 'app/AdminPanelPage/UPDATE_CATEGORY';
export const UPDATE_STATUS = 'app/AdminPanelPage/UPDATE_STATUS';

export const UPDATE_HEADERS = 'app/AdminPanelPage/UPDATE_HEADERS';
export const UPDATE_HEADERS_COMPLETE = 'app/AdminPanelPage/UPDATE_HEADERS_COMPLETE';
export const UPDATE_SORT = 'app/AdminPanelPage/UPDATE_SORT';

export const UPDATE_PAGE_META = 'app/AdminPanelPage/UPDATE_PAGE_META';

export const UPDATE_PER_PAGE = 'app/AdminPanelPage/UPDATE_PER_PAGE';
export const UPDATE_SHOW_ALL = 'app/AdminPanelPage/UPDATE_SHOW_ALL';

export const UPDATE_IS_SEARCH_CONFIG_MODAL_OPEN =
  'app/AdminPanelPage/UPDATE_IS_SEARCH_CONFIG_MODAL_OPEN';
export const UPDATE_IS_CONFIRM_LISTING_DELETE_MODAL_OPEN =
  'app/AdminPanelPage/UPDATE_IS_CONFIRM_LISTING_DELETE_MODAL_OPEN';
export const UPDATE_ID_TO_DELETE = 'app/AdminPanelPage/UPDATE_ID_TO_DELETE';

export const CLEAR_SEARCH_PARAMS = 'app/AdminPanelPage/CLEAR_SEARCH_PARAMS';

export const REMOVE_LISTING = 'app/AdminPanelPage/REMOVE_LISTING';
export const SET_HEADERS = 'app/AdminPanelPage/SET_HEADERS';

export const UPDATE_LISTING_REQUEST = 'app/AdminPanelPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/AdminPanelPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/AdminPanelPage/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  listings: [],
  headers: {},
  sort: '',
  searchValue: '',
  category: '',
  status: '',
  pageMeta: {
    page: 1,
    paginationLimit: 0,
    perPage: 3,
    totalItems: 0,
    totalPages: 0,
  },
  fetchInProgress: false,
  perPage: 20,
  showAll: false,
  isSearchConfigModalOpen: false,
  isConfirmListingDeleteModalOpen: false,
  idToDelete: null,
  users: [],
  fetchUsersInProgress: false,
  fetchUsersError: null,
  usersPageMeta: {
    page: 1,
    paginationLimit: 0,
    perPage: 3,
    totalItems: 0,
    totalPages: 0,
  },
  updateListingInProgress: false,
  updateListingError: null,
};

function checkPageOutOfRange(page, totalPages) {
  if (page < 1) return 1;
  if (page > 100) return 100;
  return page > totalPages ? 1 : page;
}

function saveToLocalStorage(updatedHeaders) {
  window.localStorage.setItem('headers', JSON.stringify(updatedHeaders));
}

export default function adminPanelPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_LISTINGS_SUCCESS: {
      const listings = payload.data.data;
      let pageMeta = payload.data.meta;
      pageMeta.page = checkPageOutOfRange(pageMeta.page, pageMeta.totalPages);
      return {
        ...state,
        fetchError: null,
        listings: listings,
        pageMeta: pageMeta,
        fetchInProgress: false,
      };
    }
    case FETCH_LISTINGS_ERROR:
      return { ...state, fetchInProgress: false, fetchError: payload };
    case FETCH_USERS_REQUEST:
      return { ...state, fetchUsersInProgress: true, fetchUsersError: null };
    case FETCH_USERS_SUCCESS: {
      const users = payload.data.data;
      let usersPageMeta = payload.data.meta;
      usersPageMeta.page = checkPageOutOfRange(usersPageMeta.page, usersPageMeta.totalPages);
      return {
        ...state,
        fetchUsersError: null,
        users: users,
        usersPageMeta: usersPageMeta,
        fetchUsersInProgress: false,
      };
    }
    case FETCH_USERS_ERROR:
      return { ...state, fetchUsersInProgress: false, fetchUsersError: payload };
    case UPDATE_HEADERS: {
      const { field, isOn } = payload;
      const headers = { ...state.headers };
      headers[field].isOn = isOn;
      saveToLocalStorage(headers);
      return {
        ...state,
        headers: headers,
      };
    }
    case UPDATE_SORT: {
      let sort = '';
      if (state.sort === payload) {
        sort = '-' + payload;
      } else if (state.sort === '-' + payload) {
        sort = '';
      } else {
        sort = payload;
      }
      return {
        ...state,
        sort: sort,
      };
    }
    case UPDATE_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: payload,
      };
    }
    case UPDATE_CATEGORY: {
      return {
        ...state,
        category: payload,
      };
    }
    case UPDATE_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }
    case UPDATE_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
        showAll: false,
      };
    }
    case UPDATE_PAGE_META: {
      return {
        ...state,
        pageMeta: payload,
      };
    }
    case UPDATE_SHOW_ALL: {
      return {
        ...state,
        showAll: payload,
      };
    }
    case UPDATE_HEADERS_COMPLETE: {
      const updatedHeaders = { ...state.headers };
      const { items } = payload;
      const itemsKeys = Object.keys(items);
      // const headersKeys = Object.keys(state.headers);
      // if (itemsKeys.length !== headersKeys.length) {
      //   saveToLocalStorage(updatedHeaders);
      //   return state;
      // }
      itemsKeys.forEach(key => {
        const changedHeader = items[key];
        if (
          !!state.headers[key] &&
          (state.headers[key].isOn !== changedHeader.isOn ||
            state.headers[key].order !== changedHeader.order)
        ) {
          updatedHeaders[key].isOn = changedHeader.isOn;
          updatedHeaders[key].order = changedHeader.order;
        }
      });

      saveToLocalStorage(updatedHeaders);
      return {
        ...state,
        headers: updatedHeaders,
      };
    }
    case UPDATE_IS_SEARCH_CONFIG_MODAL_OPEN: {
      return {
        ...state,
        isSearchConfigModalOpen: payload,
      };
    }
    case UPDATE_IS_CONFIRM_LISTING_DELETE_MODAL_OPEN: {
      return {
        ...state,
        isConfirmListingDeleteModalOpen: payload,
      };
    }
    case UPDATE_ID_TO_DELETE: {
      return {
        ...state,
        idToDelete: payload,
      };
    }

    case CLEAR_SEARCH_PARAMS: {
      return {
        ...state,
        searchValue: '',
        category: '',
        status: '',
      };
    }
    case REMOVE_LISTING: {
      const listings = state.listings.filter(listing => listing.id.uuid !== payload);
      return {
        ...state,
        listings: listings,
      };
    }
    case SET_HEADERS: {
      const isUsersPage = payload === 'users';
      const headers = isUsersPage ? userHeaders : listingHeaders;
      return {
        ...state,
        headers: headers,
      };
    }
    case UPDATE_LISTING_REQUEST:
      return { ...state, updateListingInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS: {
      const listing = payload.data.data;
      const listingIndex = findIndex(state.listings, l => l.id.uuid === listing.id.uuid);
      let updatedListings = state.listings;
      updatedListings.splice(listingIndex, 1, listing);
      return {
        ...state,
        listings: [...state.listings],
        updateListingInProgress: false,
      };
    }
    case UPDATE_LISTING_ERROR:
      return { ...state, updateListingInProgress: false, updateListingError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingsRequest = e => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: e,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});
const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchUsersRequest = e => ({
  type: FETCH_USERS_REQUEST,
  payload: e,
});
export const fetchUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: response,
});
const fetchUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

export const updateHeaders = ({ field, isOn }) => ({
  type: UPDATE_HEADERS,
  payload: { field, isOn },
});

export const updateSort = sort => ({
  type: UPDATE_SORT,
  payload: sort,
});

export const updateSearchValue = searchValue => ({
  type: UPDATE_SEARCH_VALUE,
  payload: searchValue,
});

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: category,
});

export const updateStatus = status => ({
  type: UPDATE_STATUS,
  payload: status,
});

export const updatePageMeta = pageMeta => ({
  type: UPDATE_PAGE_META,
  payload: pageMeta,
});

export const updatePerPage = perPage => ({
  type: UPDATE_PER_PAGE,
  payload: perPage,
});

export const updateShowAll = showAll => ({
  type: UPDATE_SHOW_ALL,
  payload: showAll,
});

export const updateHeadersComplete = headers => ({
  type: UPDATE_HEADERS_COMPLETE,
  payload: headers,
});

export const updateIsSearchConfigModalOpen = payload => ({
  type: UPDATE_IS_SEARCH_CONFIG_MODAL_OPEN,
  payload: payload,
});

export const updateIsConfirmListingDeleteModalOpen = payload => ({
  type: UPDATE_IS_CONFIRM_LISTING_DELETE_MODAL_OPEN,
  payload: payload,
});
export const updateIdToDelete = payload => ({
  type: UPDATE_ID_TO_DELETE,
  payload: payload,
});
export const clearSearchParams = () => ({
  type: CLEAR_SEARCH_PARAMS,
});
export const removeListing = id => ({
  type: REMOVE_LISTING,
  payload: id,
});

export const setPageHeaders = pageId => ({
  type: SET_HEADERS,
  payload: pageId,
});

const updateListingRequest = e => ({
  type: UPDATE_LISTING_REQUEST,
  payload: e,
});
export const updateListingSuccess = response => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: response,
});
const updateListingError = e => ({
  type: UPDATE_LISTING_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //
export const loadData = ({
  searchValue = '',
  sort = '',
  page = 1,
  category = '',
  perPage,
  showAll,
  status,
  pageId,
}) => async (dispatch, getState, sdk) => {
  // export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  // Get the current state
  const state = getState();
  const isUsersPage = pageId === 'users';
  // Access the required values from the state
  const currentSearchValue = state.AdminPanelPage.searchValue;
  const currentSort = state.AdminPanelPage.sort;
  const currentCategory = state.AdminPanelPage.category;
  const currentPerPage = state.AdminPanelPage.perPage;
  const currentShowAll = state.AdminPanelPage.showAll;
  const currentStatus = state.AdminPanelPage.status;

  // Use the values from the state if not provided as arguments
  searchValue = searchValue || currentSearchValue;
  sort = sort || currentSort;
  category = category || currentCategory;
  perPage = perPage || currentPerPage;
  showAll = showAll || currentShowAll;
  status = status || currentStatus;
  dispatch(setPageHeaders(pageId));
  if (isUsersPage) {
    dispatch(fetchUsersRequest());
    try {
      const response = await getAdminUsers({
        searchValue,
        sort,
        page,
        perPage: showAll ? 100 : perPage,
        showAll,
        status,
      });
      dispatch(fetchUsersSuccess(response));
    } catch (e) {
      dispatch(fetchUsersError(e));
    }
  } else {
    dispatch(fetchListingsRequest());
    try {
      const response = await getAdminListings({
        searchValue,
        sort,
        page,
        category,
        perPage: showAll ? 100 : perPage,
        showAll,
        status,
      });
      dispatch(fetchListingsSuccess(response));
    } catch (e) {
      dispatch(fetchListingsError(e));
    }
  }
};

export const updateAdminListing = (payload) => async (dispatch, getState, sdk) => {
  dispatch(updateListingRequest());
  try {
    const response = await updateListing(payload);
    dispatch(updateListingSuccess(response));
  } catch (e) {
    dispatch(updateListingError(e));
  }
};

export const createListing = ({
  id,
  category,
  description,
  streetNumber,
  streetName,
  unit,
  city,
  zipCode,
  title,
  priceAmount,
}) => async (dispatch, getState, sdk) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await postAdminListingNew({
        id,
        category,
        description,
        streetNumber,
        streetName,
        unit,
        city,
        zipCode,
        title,
        priceAmount,
      });
      resolve(response);
    } catch (e) {
      console.log('error', e);
    }
  });

  return promise;
};
// ================ Selectors ================ //

export const getPageMeta = state => state.AdminPanelPage.pageMeta;
export const getUsersPageMeta = state => state.AdminPanelPage.usersPageMeta;
export const getSort = state => state.AdminPanelPage.sort;
export const getPerPage = state => state.AdminPanelPage.perPage;
export const getSearchValue = state => state.AdminPanelPage.searchValue;
export const getHeaders = state => state.AdminPanelPage.headers;
export const getIsSearchConfigModalOpen = state => state.AdminPanelPage.isSearchConfigModalOpen;
export const getIsConfirmListingDeleteModalOpen = state =>
  state.AdminPanelPage.isConfirmListingDeleteModalOpen;
export const getShowAll = state => state.AdminPanelPage.showAll;
export const getIdToDelete = state => state.AdminPanelPage.idToDelete;
